/* eslint-disable prefer-destructuring */
import React from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// Styles
import theme from 'styles/Theme'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const BreadcrumbWrapper = styled.div`
  height: 50px;

  .breadcrumb {
    background-color: transparent;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding: 0;

    @media (max-width: 500px) {
      padding-left: 0;
      padding-right: 3px;

      .breadcrumb__link {
        font-size: ${theme.fontSizeS}px !important;
      }

      .breadcrumb__separator {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }
    }

    &__list {
      display: flex;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }

    :last-child {
      .breadcrumb__link {
        color: ${theme.colorTextStriking} !important;
      }
    }

    .breadcrumb__link {
      font-size: ${(props) => props.theme.font.size.sm} !important;

      &[aria-current] {
        font-weight: ${(props) => props.theme.font.weight.xl} !important;
      }
    }

    .breadcrumb__separator {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`

const CustomBreadCrumb = ({ data, className, pageContext }) => {
  let crumbs = pageContext.breadcrumb.crumbs

  if (crumbs.length > 0) {
    let crumbIndex = 0
    crumbs.forEach((crumb) => {
      let niceCrumbLabel = crumb.crumbLabel.replaceAll('-', ' ')
      crumbs[crumbIndex].crumbLabel =
        niceCrumbLabel.charAt(0).toUpperCase() + niceCrumbLabel.slice(1)

      crumbIndex = crumbIndex + 1
    })
  }

  return (
    <BreadcrumbWrapper
      className={`d-flex align-items-center color-soft ${
        className ? `${className}` : ``
      }`}
    >
      <div className="container">
        <Breadcrumb
          crumbSeparator=" > "
          crumbs={pageContext.breadcrumb.crumbs}
          crumbLabel={parse(data)}
        />
      </div>
    </BreadcrumbWrapper>
  )
}

export default CustomBreadCrumb
